import { useEffect, useState } from 'react'

import {
  Alert, Row, Col, Button,
  Card, Image,
  Modal,
  Navbar, Container,
} from 'react-bootstrap'

import { useAppDispatch } from '../../redux/Hook'

import ButtonStart from '../../assets/images/button-start.png'
import ButtonCancel from '../../assets/images/button-cancel.png'

import RuleInfo from './Rule'

import RequestLib from '../../libs/Request'

interface Props {
  show: boolean;
  onSelect: (serial: string, start?: boolean) => void;
  onClose: () => void;
  match: string;
  amount: number;
}

const TIMEOUT = 10;
var counterTimer: any;

function SelectPage({ show, onSelect, onClose, match, amount }: Props) {
  const dispatch = useAppDispatch()

  const [credit, setCredit] = useState(0)
  const [bet, setBet] = useState<{ amount: number, choice?: string, total_play: number, competiter?: string, amount_sum: number }>({ amount: 0, choice: '', total_play: 0, competiter: '', amount_sum: 0 })
  const [counter, setCounter] = useState<number>(TIMEOUT)

  useEffect(() => {
    setCounter(TIMEOUT)
    //setBet({ amount: amount, choice: '' })
    if (show) load()

    console.log('Match', match)

  }, [show])

  useEffect(() => {

    if (show) {

      if (counter <= 0) {
        close()
      }
      else {
        counterTimer = setTimeout(() => {
          setCounter(c => c - 1)
        }, 1000)
      }
    }

  }, [show, counter])


  async function load() {

    const { response, error } = await RequestLib.get('app/match/select/' + match)
    if (error) {
      console.error(error)
      alert('ไม่สามารถแสดงข้อมูลได้ขณะนี้')
      close()
    } else {

      if (response.data?.success) {
        console.log(response.data?.data)
        const current_credit = response.data?.data?.credit || 0

        setCredit(current_credit)

        setBet(response.data?.data?.match)


        /*
        let list_amount = filter(response.data?.data?.amounts || [], (amt) => { if (current_credit >= amt) return amt })
        setAmounts(list_amount)

        let list_choice = [...choices]
        for (const ch of response.data?.data?.choices) {
          let idx = findIndex(list_choice, { code: ch.code })
          if (idx !== -1) list_choice[idx].id = ch.id
        }

        setChoices(list_choice)
        */
      } else {
        alert(response.data?.message)
      }

    }
  }

  async function start() {

    //onSelect(match, true)

    let data = {
      ...bet,
      match,
    }

    const { response, error } = await RequestLib.post('app/match/play', data)
    if (error) {
      console.error(error)
      alert('ไม่สามารถสร้างได้ขณะนี้')
    } else {

      if (response.data?.success) {

        let match_serial = response.data?.data?.match?.id

        onSelect(match_serial, true)
        close()

      } else {
        alert(response.data?.message)
      }
    }
    //onSelect(bet.amount, bet.choice)
    //close()
  }

  function close() {
    if (counterTimer) clearTimeout(counterTimer)
    if (onClose) onClose()
  }


  return (
    <Modal show={show} onHide={close} backdrop='static' fullscreen={true}>

      <Modal.Body className="bg bg-1">

        {bet?.competiter &&
          <Card border="dark" className="my-2" >
            <Card.Header className="text-center">
              <Card.Title>VS</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text className="text-center">
                <h1 className="text-warning">{bet?.competiter.toUpperCase()}</h1>
              </Card.Text>
            </Card.Body>
          </Card>
        }

        <Row className="my-2" style={{ display: 'none' }}>
          <Col xs={6}>
            <Card border="dark"  >
              <Card.Header className="text-center">
                <Card.Title>รอบละ</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Text className="text-center">
                  <h1 className="text-primary">{bet?.amount}</h1>
                  บาท
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card border="dark" >
              <Card.Header className="text-center">
                <Card.Title>เล่นทั้งหมด</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Text className="text-center">
                  <h1 className="text-primary">{bet?.total_play}</h1>
                  รอบ
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Card border="dark" className="my-2" >
          <Card.Header className="text-center">
            <Card.Title>รวมเป็นจำนวนเงิน</Card.Title>
          </Card.Header>
          <Card.Body>
            <Card.Text className="text-center">
              <h1 className="text-info">{bet?.amount_sum}</h1>

              {(bet?.amount_sum > credit) &&
                <h4 className="text-danger">จำนวนเครดิตไม่พอ</h4>
              }

            </Card.Text>
          </Card.Body>
        </Card>

        <Row className="my-4">
          <Col className="text-center">
            <Card border="dark" className="my-2" >
              <Card.Body className="text-center">
                กรุณากดเริ่มเล่นภายใน
                <h1 style={{ fontSize: 48 }}>{counter}</h1>
              </Card.Body>
            </Card>
          </Col>
        </Row>


        <RuleInfo variant="warning" />


        <Navbar fixed="bottom" >
          <Container>
            <Navbar.Offcanvas placement="end">
              <Row>
                <Col xs={8} style={{ padding: 2 }}>
                  <Image src={ButtonStart} className="button-bar" onClick={start} />
                </Col>
                <Col style={{ padding: 2 }} className="text-center">
                  <Image src={ButtonCancel} className="button-bar" onClick={close} />
                </Col>
              </Row>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>

      </Modal.Body>
    </Modal>


  )
}

export default SelectPage