import { useEffect, useState } from 'react'

import {
  Container, Row, Col, Button,
  Navbar, Image,
  Form,
} from 'react-bootstrap'

import { filter, findIndex, chunk } from 'lodash'
import { useAppDispatch } from '../redux/Hook'
import { useNavigate } from 'react-router-dom'

import TopBar from '../components/TopBar'
import RuleInfo from '../components/challenge/Rule'

import ChoicePaper from '../assets/images/rps-1.png'
import ChoiceRock from '../assets/images/rps-2.png'
import ChoiceScissor from '../assets/images/rps-3.png'
import IconBaht from '../assets/images/baht.png'
import ButtonStart from '../assets/images/button-start.png'
import ButtonCancel from '../assets/images/button-cancel.png'
import ImageSepecifyAmountTitle from '../assets/images/title-specify-amount.png'

import ImageSelectTitle from '../assets/images/create-select-amount.png'


import '../assets/css/TopBar.css'

import RequestLib from '../libs/Request'

interface Bet {
  amount: number;
  total: number;
  choice?: string;
}

const TOTAL_ROUND = 5;


const CHOICES = [
  { code: 'paper', icon: ChoicePaper, id: '1' },
  { code: 'rock', icon: ChoiceRock, id: '2' },
  { code: 'scissor', icon: ChoiceScissor, id: '3' },
]


function PlayPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [credit, setCredit] = useState(0)
  const [bet, setBet] = useState<Bet>({ amount: 0, total: 1 })
  const [amounts, setAmounts] = useState<number[]>([])
  const [listAmount, setListAmount] = useState<number[]>([])
  const [rounds, setRounds] = useState<number[]>([])
  const [summary, setSummary] = useState<number>(0)

  const [choices, setChoices] = useState([
    { code: 'paper', icon: ChoicePaper, id: '' },
    { code: 'rock', icon: ChoiceRock, id: '' },
    { code: 'scissor', icon: ChoiceScissor, id: '' },
  ])

  useEffect(() => {
    setBet({ amount: 0, total: 1, choice: '' })
    load()

  }, [])


  async function load() {

    const { response, error } = await RequestLib.get('app/match')
    if (error) {
      console.error(error)
      alert('ไม่สามารถแสดงข้อมูลได้ขณะนี้')
      close()
    } else {

      if (response.data?.success) {
        const current_credit = response.data?.data?.credit || 0

        setCredit(current_credit)
        setListAmount(response.data?.data?.amounts || [])

        let list_amount = filter(response.data?.data?.amounts || [], (amt) => { if (current_credit >= amt) return amt })
        setAmounts(list_amount)

        let list_round = []
        for (let i = 1; i <= TOTAL_ROUND; ++i) {
          list_round.push(i)
        }
        setRounds(list_round)

        let list_choice = [...choices]
        for (const ch of response.data?.data?.choices) {
          let idx = findIndex(list_choice, { code: ch.code })
          if (idx !== -1) list_choice[idx].id = ch.id
        }

        setChoices(list_choice)
      } else {
        alert(response.data?.message)
      }

    }
  }

  async function start() {

    let data = {
      bet: bet?.amount || 0,
      total: bet?.total || 1,
      //choice: bet?.choice || '',
    }

    const { response, error } = await RequestLib.post('app/match', data)
    if (error) {
      console.error(error)
      alert('ไม่สามารถสร้างได้ขณะนี้')
    } else {


      if (response.data?.success) {

        const match_serial = response.data?.data?.match
        //preparePlay(match_serial)
        close(match_serial)

      } else {
        alert(response.data?.message)
      }
    }
    //onSelect(bet.amount, bet.choice)
    //close()
  }

  async function preparePlay(match_serial: string) {
    const { response, error } = await RequestLib.post('app/match/start', { match: match_serial })
    if (error) {
      console.error(error)
      alert('ไม่สามารถสร้างได้ขณะนี้')
    } else {

      if (response.data?.success) {

        const match_play_serial = response.data?.data?.match?.id

        //onSelect(match_play_serial, true)
        close(match_play_serial)

      } else {
        alert(response.data?.message)
      }
    }
  }

  function setValue(field: string, value: number) {
    const this_bet = { ...bet, [field]: value }

    const sum = (this_bet.amount || 0) * (this_bet.total || 1)

    setBet(this_bet)
    setSummary(sum)
  }

  function close(match?: any) {
    let path = '/start'
    if (match) path += '?play=' + match
    navigate(path)
    // if (onClose) onClose()
  }


  return (
    <>
      <TopBar />
      <Container>

        {(amounts.length > 0) ? (
          <>
            <Row className="mb-2">
              <Col className="text-center py-2 ">
                <Image src={ImageSelectTitle} fluid />
              </Col>
            </Row>

            <Row className="mb-4 mx-2">
              {amounts.map((amount, i) => {
                return (
                  <Col xs={4} key={i} style={{ padding: 2 }} className={'button-amount mb-1 amount-border' + ((amount === bet?.amount) ? '-select' : '')}>
                    <div className="d-grid gap-2">
                      <Button variant="outline-secondary" size="lg" onClick={() => setValue('amount', amount)}><img src={IconBaht} className="icon-coin" alt="" /> <small>{amount}</small></Button>
                    </div>
                  </Col>
                )

              })}
            </Row>
          </>
        ) : (
          <>

            <Row>
              <Col className="text-center py-2">
                <h4>ระบุจำนวนเงิน</h4>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs={{ span: 6, offset: 3 }}>
                <Form.Control type="number" size="lg" className="text-center input-outer" min={1} max={credit} style={{ fontSize: 24 }} required onChange={(e) => setValue('amount', parseInt(e.target.value))} />
              </Col>
            </Row>

          </>
        )}


        {(credit > 0) &&
          <div style={{ display: 'none' }}>
            <Row>
              <Col className="text-center py-2">
                <h4>จำนวนรอบที่ต้องการเล่น</h4>
              </Col>
            </Row>
            <Row className="justify-content-xs-center mb-4">

              {rounds.map((r, i) => {
                return (
                  <Col xs={4} key={i}>
                    <div className="d-grid gap-2">
                      <Button className="mb-4" variant={(r === bet?.total) ? 'primary' : 'outline-primary'} size="lg" onClick={() => setValue('total', r)}>{r}</Button>
                    </div>
                  </Col>
                )

              })}
            </Row>

            <Row>
              <Col className="text-center ">
                <h4>รวมเป็นเงินทั้งหมด</h4>
              </Col>
            </Row>
            <Row>
              <Col className="text-center py-2">
                <h1><span className="text-primary">{summary}</span> บาท</h1>
              </Col>
            </Row>

            {(summary > credit) &&
              <Row>
                <Col className="text-center py-2 text-danger">
                  <h3>จำนวนเครดิตไม่เพียงพอ</h3>
                </Col>
              </Row>
            }
          </div>
        }


        <Row>
          <Col className="text-center py-2">
            <Image src={ImageSepecifyAmountTitle} style={{ maxHeight: 40 }} />
          </Col>
        </Row>

        <Row className="mb-4">
          <Col xs={{ span: 6, offset: 3 }}>
            <Form.Control type="number" size="lg" className="text-center" min={1} max={credit} style={{ fontSize: 24 }} required onChange={(e) => setValue('amount', parseInt(e.target.value))} value={bet?.amount} />
          </Col>
        </Row>


        <RuleInfo variant="warning" />

        <Navbar fixed="bottom" >
          <Container>
            <Navbar.Offcanvas placement="end">
              <Row>
                {(summary < credit && summary > 0) &&
                  <Col xs={8} style={{ padding: 2 }}>
                    <Image src={ButtonStart} className="button-bar" onClick={start} />
                  </Col>
                }
                <Col style={{ padding: 2 }} className="text-center">
                  <Image src={ButtonCancel} className="button-bar" onClick={() => { close() }} />
                </Col>
              </Row>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>

      </Container>
    </>

  )
}

export default PlayPage