import { useEffect, useState } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket';
import useSound from 'use-sound';

import {
  Container, Row, Col, Button,
  Navbar, Image,
  Modal,
  Badge,
} from 'react-bootstrap'
import { find, sample, random } from 'lodash'
import Lottie from "lottie-react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeHigh, faVolumeXmark } from '@fortawesome/free-solid-svg-icons'

import RequestLib from '../../libs/Request'
import LocalStorage from '../../libs/LocalStorage'
import { LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_SETTING } from '../../constants/LocalStorage'

import ChoicePaper from '../../assets/images/rps-1.png'
import ChoiceRock from '../../assets/images/rps-2.png'
import ChoiceScissor from '../../assets/images/rps-3.png'
import IconBaht from '../../assets/images/baht.png'
import ImageResultWin from '../../assets/images/result-win.png'
import ImageResultLose from '../../assets/images/result-lose.png'
import ImageResultDraw from '../../assets/images/result-draw.png'
import ButtonBack from '../../assets/images/button-back2.png'
import ButtonNew from '../../assets/images/button-new.png'

import Avatar1 from '../../assets/images/avatar-1.png'
import Avatar2 from '../../assets/images/avatar-2.png'
import Avatar3 from '../../assets/images/avatar-3.png'

import WaitAnimation from '../../assets/lottie/wait.json'

import SoundResultWin from '../../assets/sound/result-win.mp3'
import SoundResultDraw from '../../assets/sound/result-draw.mp3'
import SoundResultLose from '../../assets/sound/result-lose.mp3'
import SoundResultSelectChoice from '../../assets/sound/select-choice.mp3'


import { Setting as SettingProp } from '../../constants/Props'

import '../../assets/css/play.css'

const CHOICES = [
  { code: 'paper', icon: ChoicePaper, id: '1' },
  { code: 'rock', icon: ChoiceRock, id: '2' },
  { code: 'scissor', icon: ChoiceScissor, id: '3' },
]


const AVATAR = [
  Avatar1, Avatar2, Avatar3
]


interface Props {
  show: boolean;
  start?: boolean;
  match: string;
  onClose: (amount: number) => void;
}

interface MatchInfo {
  id: string;
  no: number;
  total_play: number;
  amount: number;
}

interface Competitor {
  id?: string;
  username?: string;
  choice?: string;
}

interface Choice {
  code: string;
  icon: string;
  id: string;
}

const CHOICE_TIMEOUT = 5
var counterChoiceTimer: any;
var counterRematchTimer: any;

function PlayPage({ show, start, match, onClose }: Props) {

  const WS_URL = process.env.REACT_APP_WS_URL + '/room'

  const [matchInfo, setMatchInfo] = useState<MatchInfo>();
  const [completed, setCompleted] = useState<boolean>(false)
  const [youChoice, setYouChoice] = useState<Choice>()
  const [competitorChoice, setCompetitorChoice] = useState<string>('')
  const [competitor, setCompetitor] = useState<Competitor>()
  const [result, setResult] = useState<string>('')
  const [showResult, setShowResult] = useState<boolean>(false)
  const [showChoice, setShowChoice] = useState<boolean>(false)
  const [choice, setChoice] = useState<string>('')
  const [choices, setChoices] = useState<Choice[]>([])
  const [counterChoice, setCounterChoice] = useState<number>(CHOICE_TIMEOUT)
  const [records, setRecords] = useState<any[]>([])
  const [isOwn, setIsOwn] = useState<boolean>()
  const [avatar, setAvatar] = useState<string>()
  const [player, setPlayer] = useState<string>()
  const [credit, setCredit] = useState<number>()
  const [allowNext, setAllowNext] = useState<boolean>()
  const [showRematch, setShowRematch] = useState<boolean>()
  const [counterRematch, setCounterRematch] = useState<number>(CHOICE_TIMEOUT)
  const [messageStatus, setMessageStatus] = useState<string>('')

  const [playSoundWin, { stop: stopSoundWin }] = useSound(SoundResultWin);
  const [playSoundDraw, { stop: stopSoundDraw }] = useSound(SoundResultDraw);
  const [playSoundLose, { stop: stopSoundLose }] = useSound(SoundResultLose);
  const [playSoundSelectChoice] = useSound(SoundResultSelectChoice);

  const [setting, setSetting] = useState<SettingProp>()


  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(WS_URL, {
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    retryOnError: true,
  });


  useEffect(() => {

    setSetting(LocalStorage.get(LOCAL_STORAGE_SETTING) || {})

  }, [])


  useEffect(() => {
    setCompetitorChoice('')
    setYouChoice({ code: '', icon: '', id: '' })
    setResult('')
    setShowResult(false)
    setChoice('')
    setCounterChoice(CHOICE_TIMEOUT)
    setCompetitor({})

    if (show && match) {
      document.body.style.overflow = 'hidden';


      if (readyState === ReadyState.OPEN) {
        setAvatar(sample(AVATAR))
        play(match)
      }
    }


  }, [show, match, readyState])

  useEffect(() => {

    if (lastMessage && lastMessage.data) {

      let isJSONString = false

      try {
        JSON.parse(lastMessage.data)
        isJSONString = true
      } catch (error) {

      }

      try {

        const respData = !isJSONString ? lastMessage.data : JSON.parse(lastMessage.data)
        if (respData?.match && respData?.match === matchInfo?.id) {
          console.log(respData)
          switch (respData?.action) {
            case 'enter':

              break;

            case 'competitor':

              const this_competitor = respData?.competitor
              if (this_competitor?.id) {

                const is_rematch = respData?.competitor?.rematch || false

                setTimeout(() => {
                  setMessageStatus('พบคู่ต่อสู้แล้ว')
                  setCompetitor({ id: this_competitor.id, username: this_competitor.username })
                }, is_rematch ? random(1, 2) : 0 * 1000);

              } else {
                // no this_competitor
              }

              break;

            case 'select':
              playSound('select_choice')
              setShowChoice(true)
              break;

            case 'status':

              switch (respData?.result) {
                case 'draw':
                  // setResult('draw')
                  // setShowResult(true)
                  if (youChoice) showCompetitorChoice(youChoice.code, 'draw')
                  break;

                case 'complete':
                  send({}, matchInfo, player)
                  break;

                default:

                  break;
              }

              break;

            case 'result':

              finish(respData)

              break;

            case 'submit':

              break;

            case 'rematch_request':

              if (!isOwn) {
                rematchRequest();
              } else {
                setMessageStatus('รอการตอบรับ')
              }

              break;

            case 'rematch_reject':
              if (isOwn) {
                setMessageStatus('ถูกปฏิเสธที่จะเล่นต่อ')

                setTimeout(() => {

                  setMessageStatus('กำลังหาคู่ต่อสู้รายใหม่')

                  setTimeout(() => {
                    send({ action: 'competitor' }, matchInfo, player)
                  }, random(3, 7) * 1000);

                }, random(1, 3) * 1000);
              }
              break;

            case 'error':
              alert('เกิดความผิดพลาด ไม่สามารถเล่นได้ขณะนี้')
              close()
              break;
            default:
              console.log('Message', respData)
              break;
          }

        } else {
          console.error('Invalid response', 'match', respData)
        }

      } catch (error) {
        console.error('Invalid response data', lastMessage.data)
      }

    }

  }, [lastMessage])


  useEffect(() => {

    if (result === 'draw') {
      setTimeout(() => {
        setCompetitorChoice('')
        setYouChoice({ code: '', icon: '', id: '' })
        setResult('')
        setShowResult(false)
        setChoice('')
        setCounterChoice(CHOICE_TIMEOUT)
        setCompleted(false)
        setMessageStatus('')

        playSound('select_choice')
        setShowChoice(true)
      }, 2 * 1000);
    }

    if (result) {
      playSound(result)
    }

  }, [result])

  useEffect(() => {

    switch (readyState) {
      case ReadyState.CONNECTING:
        console.log('Connecting WS');
        break;

      case ReadyState.OPEN:
        console.log('OPEN WS');
        break;

      case ReadyState.CLOSING:
        console.log('Closing WS');
        break;

      case ReadyState.CLOSED:
        console.log('Closed WS');
        break;

      default:
        console.log('Ready State', readyState)
        break;
    }

  }, [readyState, match])


  useEffect(() => {

    if (showChoice) {
      setMessageStatus('')

      if (counterChoice <= 0) {
        selectChoice()
      }
      else {
        counterChoiceTimer = setTimeout(() => {
          setCounterChoice(c => c - 1)
        }, 1000)
      }
    }


  }, [showChoice, counterChoice])


  useEffect(() => {

    if (showRematch) {
      if (counterRematch <= 0) {
        rematchReject()
      }
      else {
        counterRematchTimer = setTimeout(() => {
          setCounterRematch(c => c - 1)
        }, 1000)
      }
    }


  }, [showRematch, counterRematch])


  useEffect(() => {

    if (competitor?.id) {
      setTimeout(() => {
        send({ action: 'enter' }, matchInfo, player)
      }, random(1, 3) * 1000)
    }

  }, [competitor])


  useEffect(() => {

    if (showResult) {

      if (matchInfo && credit && credit > 0) {

        setAllowNext((isOwn && credit >= matchInfo.amount))

      } else {
        setAllowNext(false)
      }

    } else {
      setAllowNext(false)
    }

  }, [credit, matchInfo, showResult, isOwn])


  async function play(match_serial: string, next?: boolean) {
    setCompetitorChoice('')
    setYouChoice({ code: '', icon: '', id: '' })
    setResult('')
    setShowResult(false)
    setChoice('')
    setCounterChoice(CHOICE_TIMEOUT)
    setCompleted(false)
    setRecords([])
    setCompetitor({})
    setMessageStatus('')
    stopSound()

    console.log('Play', match_serial)


    const { response, error } = await RequestLib.get('app/match/play/' + match_serial)
    if (error) {
      console.error(error)
      //alert('ไม่สามารถแสดงข้อมูลได้ขณะนี้')
      close()
    } else {

      if (response.data?.success) {

        const info = response.data?.data?.match

        setMatchInfo(info)

        let list_choice = (response.data?.data?.choices || []).map((ch: any) => {

          let c = find(CHOICES, { code: ch.code })
          if (c) {
            c.id = ch.id
            return c
          }
        })


        setChoices(list_choice)

        const this_player = response.data?.data?.player
        setPlayer(this_player)

        const is_own = response.data?.data?.own || false
        setIsOwn(is_own)



        if (!is_own) {
          const this_competitor = response.data.data.competiter//.toUpperCase()

          setCompetitor({ id: this_competitor.id, username: this_competitor.username })

        } else {

          //Wait

          if (!competitor?.id) {

            setMessageStatus('รอคู่ต่อสู้')
            setTimeout(() => {

              send({ action: 'competitor' }, info, this_player)

            }, random(5, 10) * 1000);
          }
          else {
            console.log('Play with competitor', competitor)
            send({ action: 'rematch' }, info, this_player, competitor.id)
          }

        }

        /*
        let timeout = random(3, 5)
        if (!next && is_own) timeout = random(8, 30)

        if (is_own) {
          let this_timeout = timeout - 5
          if (this_timeout < 3) this_timeout = 3

          setTimeout(() => {
            setCompetitor({ ...competitor, username: this_competitor })
          }, this_timeout * 1000);
        }

        setTimeout(() => {
          //setShowChoice(true)
        }, timeout * 1000)
        */

      } else {
        alert(response.data?.message)
        close()
      }

    }


  }

  async function selectChoice() {
    if (counterChoiceTimer) clearTimeout(counterChoiceTimer)
    setShowChoice(false)

    let choice_selected = choice;
    if (!choice) {
      const choice_rand = sample(choices)
      choice_selected = choice_rand ? choice_rand.id : ''
    }

    if (choice_selected && matchInfo?.id) {

      for (const ch of choices) {
        if (ch.id === choice_selected) setYouChoice(ch)
      }


      let data = { action: 'submit', choice: choice_selected }

      //sendMessage(JSON.stringify(data))
      setTimeout(() => {
        send(data, matchInfo, player)
      }, random(1, 3) * 1000);
    }

  }

  async function send(data: any, match_info?: MatchInfo, player_id?: string, competiter_id?: string) {
    let message = {
      match: match_info?.id,
      player: player_id,
      competiter: competiter_id,
      token: LocalStorage.get(LOCAL_STORAGE_TOKEN),
      ...data,
    }

    console.log('send', message)

    sendJsonMessage(message)
  }

  function finish(respData: any) {
    const matchResult = respData.result

    const is_winner = (matchResult.winner === player)
    // const players = matchResult.players || []

    // const player_me = find(matchResult.players, { you: true })
    const player_competiter = find(matchResult.players, { you: false })


    const current_credit = respData?.credit || 0

    setCredit(current_credit)

    showCompetitorChoice(player_competiter.choice, is_winner ? 'win' : 'lose')

    /*
    setTimeout(() => {

      for (const ch of choices) {
        if (ch.code === player_competiter.choice) setCompetitorChoice(ch.icon)
      }

      setTimeout(() => {
        setResult(is_winner ? 'win' : 'lose')

        setCompleted(true)

        setShowResult(true)
      }, 2 * 1000);

    }, random(1, 3) * 1000);
    */


  }

  function close(retry?: boolean) {
    setCompetitorChoice('')
    setYouChoice({ code: '', icon: '', id: '' })
    setResult('')
    setShowResult(false)
    setChoice('')
    setCounterChoice(CHOICE_TIMEOUT)
    setCompleted(false)
    stopSound()

    let amount: number = 0
    if (retry && matchInfo && allowNext) {
      amount = matchInfo.amount
    }

    if (amount > 0) {

      create(amount)

    } else if (onClose) onClose(0)

    //if (onClose) onClose(amount)
  }

  function showCompetitorChoice(competiter_choice: string, match_result: string) {
    const ch = find(CHOICES, { code: competiter_choice })

    console.log('showCompetitorChoice', competiter_choice, match_result, ch)

    let counter = 20
    const interval = setInterval(() => {

      if (counter <= 0) {
        clearInterval(interval);

        //let ch = find(CHOICES, { code: competiter_choice })
        if (ch) setCompetitorChoice(ch.icon)

        setTimeout(() => {

          setResult(match_result)

          if (match_result !== 'draw') setCompleted(true)

          setShowResult(true)
        }, 2 * 1000);

      } else {
        setCompetitorChoice(CHOICES[counter % CHOICES.length].icon)
        counter--;
      }

    }, 100);




  }


  async function create(amount: number, total: number = 1) {

    if (amount > 0) {

      let data = {
        bet: amount,
        total: total || 1,
        //choice: bet?.choice || '',
      }

      const { response, error } = await RequestLib.post('app/match', data)
      if (error) {
        console.error(error)
        alert('ไม่สามารถสร้างได้ขณะนี้')
      } else {


        if (response.data?.success) {

          const match_serial = response.data?.data?.match
          play(match_serial)
          //preparePlay(match_serial)

        } else {
          alert(response.data?.message)
        }
      }

    }

  }

  async function rematchRequest() {
    setCounterRematch(CHOICE_TIMEOUT)

    setShowRematch(true)
  }

  async function rematchConfirm() {
    send({ action: 'rematch_confirm' }, matchInfo, player, competitor?.id)
    setShowRematch(false)
  }

  async function rematchReject() {
    send({ action: 'rematch_reject' }, matchInfo, player, competitor?.id)
    setShowRematch(false)
  }

  function playSound(sound: string) {
    console.log('setting', setting)
    if (setting?.sound === 'on') {
      switch (sound) {
        case 'win': playSoundWin(); break;
        case 'lose': playSoundLose(); break;
        case 'draw': playSoundDraw(); break;
        case 'select_choice': playSoundSelectChoice(); break;
      }

    }
  }

  function stopSound() {
    stopSoundDraw()
    stopSoundLose()
    stopSoundWin()
  }

  function openSound(open: boolean) {
    let data = { ...setting, sound: open ? 'on' : 'off' }

    LocalStorage.set(LOCAL_STORAGE_SETTING, data)
    setSetting(data)
  }

  return (
    <>
      {(matchInfo && matchInfo?.id) &&

        <Modal show={show} onHide={() => close()} backdrop='static' fullscreen={true} >
          <Modal.Header closeButton={completed}>
            <Modal.Title>

              {(avatar && competitor?.username) && <Image src={avatar} height={20} width={20} className="mx-2" />}

              <strong>
                {competitor?.username?.toUpperCase()}
              </strong>

              <Badge bg="primary" pill className="mx-4"> <Image src={IconBaht} width={12} height={12} /> {matchInfo?.amount}</Badge>

              <Button variant={'link'} style={{ marginLeft: 5 }} onClick={() => openSound(setting?.sound !== 'on')}>
                <FontAwesomeIcon icon={(setting?.sound === 'on') ? faVolumeHigh : faVolumeXmark} />
              </Button>

            </Modal.Title>
          </Modal.Header>

          <Modal.Body className={'bg bg-' + ((showResult && result === 'win') ? 'win' : '1')}>



            {!showResult &&
              <Row>
                <Col className="text-center py-2">
                  {competitorChoice ? (
                    <Image src={competitorChoice} fluid className={'choice-' + (result === 'win' ? 'lose' : 'win')} style={{ maxHeight: showResult ? '50%' : '90%', maxWidth: showResult ? '50%' : '90%' }} />
                  ) : (
                    <>
                      <Lottie animationData={WaitAnimation} />
                      {messageStatus && <h1 className='my-2'>{messageStatus}</h1>}
                    </>
                  )}
                </Col>
              </Row>
            }

            {showResult &&
              <Row>
                <Col className="text-center">
                  <Image src={youChoice?.icon} fluid style={{ maxHeight: '90%', maxWidth: '90%' }} className="mb-2" />

                  {(result === 'win') && <Image src={ImageResultWin} fluid />}
                  {(result === 'draw') && <Image src={ImageResultDraw} fluid />}
                  {(result === 'lose') && <Image src={ImageResultLose} fluid />}
                </Col>
              </Row>
            }

            {!showResult &&
              <Row>
                <Col className="text-center py-2">
                  {youChoice && <Image src={youChoice?.icon} fluid className={'choice-' + result} style={{ maxHeight: showResult ? '50%' : '90%', maxWidth: showResult ? '50%' : '90%' }} />}
                </Col>
              </Row>
            }



            {(showResult && result !== 'draw') &&


              <Navbar fixed="bottom" >
                <Container>
                  <Navbar.Offcanvas placement="end">
                    {allowNext ? (
                      <Row>
                        <Col xs={6} style={{ padding: 2 }} className="text-center">
                          <Image src={ButtonNew} className="button-bar" onClick={() => close(true)} />
                        </Col>
                        <Col xs={6} style={{ padding: 2 }} className="text-center">
                          <Image src={ButtonBack} className="button-bar" onClick={() => close()} />
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col style={{ padding: 2 }} className="text-center">
                          <Image src={ButtonBack} className="button-bar" onClick={() => close()} />
                        </Col>
                      </Row>
                    )}
                  </Navbar.Offcanvas>
                </Container>
              </Navbar>

            }


          </Modal.Body>


        </Modal>
      }


      <Modal
        show={showChoice}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowChoice(false)}
        backdrop="static" keyboard={false}
      >
        <Modal.Body>

          <Row className="py-4">
            <Col className="text-center">

              <h1>กรุณาเลือกภายใน</h1>
              <h1 style={{ fontSize: 36 }}>{counterChoice}</h1>
              <h5 className="text-info">ถ้าหมดเวลา ระบบจะสุ่มเลือกให้อัตโนมัติ</h5>
            </Col>
          </Row>

          <Row >
            {choices.map((ch, i) => (
              <Col xs={4} key={i}>
                <Image src={ch.icon} fluid className={choice ? ((choice === ch.id) ? 'choice-selected' : 'choice-unselect') : ''} onClick={() => setChoice(ch.id)} />
              </Col>
            ))}
          </Row>

        </Modal.Body>
      </Modal>



      <Modal
        show={showRematch}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowRematch(false)}
        backdrop="static" keyboard={false}
      >
        <Modal.Body>

          <Row className="py-4">
            <Col className="text-center">

              <h1 className='text-success'>ต้องการเล่นอีกครั้งหรือไม่?</h1>
              <h3>กรุณาเลือกภายใน</h3>
              <h1 style={{ fontSize: 36 }}>{counterRematch}</h1>
            </Col>
          </Row>

          <Row>
            <Col xs={6} style={{ padding: 2 }} className="text-center">
              <Image src={ButtonNew} className="button-bar" onClick={() => rematchConfirm()} />
            </Col>
            <Col xs={6} style={{ padding: 2 }} className="text-center">
              <Image src={ButtonBack} className="button-bar" onClick={() => rematchReject()} />
            </Col>
          </Row>

        </Modal.Body>
      </Modal>



      <Modal
        show={completed && false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => close()}
        backdrop="static" keyboard={false}
      >

        <Modal.Header >
          <Modal.Title>
            ผลการแข่งขัน
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {records.map((rc, i) => {

            let result = <Badge bg="warning">Draw</Badge>
            if (rc?.result !== 'draw') {
              const winner = rc[rc.result]

              if (winner?.me) result = <Badge bg="success">Win</Badge>
              else result = <Badge bg="danger">Lose</Badge>
            }

            return (
              <Row className="mb-2" key={i}>
                <Col xs={{ span: 3, offset: 3 }}>
                  <strong>ครั้งที่ {rc.no}</strong>
                </Col>
                <Col>
                  {result}
                </Col>
              </Row>
            )
          })}

        </Modal.Body>
        <Modal.Footer style={{
          display: "flex",
          justifyContent: "center",
        }}>
          <Button variant="secondary" className="px-4" onClick={() => close()} >ปิด</Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}


export default PlayPage