import { useEffect, useState } from 'react'
import { Container, Navbar, Image, Button, Offcanvas, Form, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/free-solid-svg-icons'


import { useAppSelector, useAppDispatch } from '../redux/Hook'

import { setCredit } from '../redux/reducer/Member';

import IconBaht from '../assets/images/baht.png'

import RequestLib from '../libs/Request'
import LocalStorage from '../libs/LocalStorage'
import { LOCAL_STORAGE_URL_CALLBACK, LOCAL_STORAGE_SETTING } from '../constants/LocalStorage'

import Avatar1 from '../assets/images/avatar-1.png'

import '../assets/css/TopBar.css'

import { Setting as SettingProp } from '../constants/Props'


function TopBar() {
  const dispatch = useAppDispatch()

  const credit = useAppSelector((state) => state.member.credit)
  const username = useAppSelector((state) => state.member.username)
  const error_code = useAppSelector((state) => state.error.code)

  const [callbackUrl, setCallbackUrl] = useState<string>('')
  const [showSetting, setShowSetting] = useState<boolean>(false)
  const [openSound, setOpenSound] = useState<boolean>()
  const [setting, setSetting] = useState<SettingProp>()

  useEffect(() => {

    const callback_url = LocalStorage.get(LOCAL_STORAGE_URL_CALLBACK)
    if (callback_url) setCallbackUrl(callback_url)

    const setting = LocalStorage.get(LOCAL_STORAGE_SETTING) || {}
    setSetting(setting)

  }, [])

  useEffect(() => {
    if (typeof openSound !== 'undefined') saveSetting('sound', openSound ? 'on' : 'off')

  }, [openSound])



  function saveSetting(field: string, value: string) {
    let setting = LocalStorage.get(LOCAL_STORAGE_SETTING) || {}

    let data = { ...setting, [field]: value }

    LocalStorage.set(LOCAL_STORAGE_SETTING, data)

    setSetting(data)
  }


  async function exit() {

    if (callbackUrl) {
      //window.location.href = callbackUrl


      const { response, error } = await RequestLib.post('app/authen/exit')
      if (error) {
        console.error(error.response)
      } else {

        let callback_url = response?.data?.data?.callback_url
        if (callback_url) window.location.href = callback_url

      }
    }

    //LocalStorage.clear()

    //window.location.href = callbackUrl

  }

  async function getCredit() {
    const { response, error } = await RequestLib.get('app/credit')
    if (error) {
      console.error(error.response)
    } else {

      //dispatch(setUsername(response?.data?.member))
      dispatch(setCredit(response?.data?.data?.credit))

    }
  }

  return (
    <>
      <Navbar fixed="top" sticky="top" bg="primary">
        <Container>
          <Navbar.Brand href="#home">
            <Image src={Avatar1} height={25} width={25} className="mx-2" />
            <small>คุณ {username}</small></Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text style={{ marginRight: 10 }}>
              <Image src={IconBaht} width={14} height={14} className="mr-2" /> {credit}
            </Navbar.Text>
            <Button variant='outline-light' size='sm' onClick={() => setShowSetting(true)}><FontAwesomeIcon icon={faGear} /></Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Offcanvas placement='end' show={showSetting} onHide={() => setShowSetting(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{username}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Form.Check type="switch" label="เสียง" defaultChecked={setting?.sound === 'on'} onChange={(e) => setOpenSound(e.target.checked)} />
            </ListGroup.Item>
            {callbackUrl &&
              <ListGroup.Item action onClick={exit}>ออกจากเกม</ListGroup.Item>
            }
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>

    </>
  )
}


export default TopBar