import { useEffect, useState } from 'react'


import {
  Row, Col,
  Badge,
  Table,
  Modal,
  Card,
} from 'react-bootstrap'

import {
  DatatableWrapper,
  Pagination,
  TableBody,
  TableHeader
} from 'react-bs-datatable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import RequestLib from '../../libs/Request'


interface Cycle {
  id: number;
  serial: string;
  budget: number;
  win_amount: number;
  win_current: number;
  lose_rate: number;
  lose_amount: number;
  lose_remain: number;
  status: string;
  start_at?: string;
  end_at?: string;
  remark?: string;
  results?: any;
}

interface TableHead {
  title: string;
  prop: string;
}


const TABLE_HEADER: TableHead[] = [
  { title: '', prop: 'icon_view' },
  { title: '#', prop: 'id' },
  { title: 'สถานะ', prop: 'status_badge' },
  { title: 'เริ่ม', prop: 'start_at' },
  { title: 'สิ้นสุด', prop: 'end_at' },
  { title: 'วงเงิน', prop: 'budget' },
  { title: 'งบเสีย', prop: 'lose_sum' },
  { title: 'คิดเป็นเงินได้', prop: 'win_amount' },
  { title: 'รวมเงินได้', prop: 'win_current' },
  { title: 'เหลือเงินเสีย', prop: 'lose_remain' },
  { title: 'หมายเหตุ', prop: 'remark' },
]


const TABLE_RESULT_HEADER: TableHead[] = [
  { title: 'วันที่', prop: 'created_at' },
  { title: 'Username', prop: 'player' },
  { title: 'ผล', prop: 'result' },
  { title: 'จำนวนเงิน', prop: 'amount' },
]

function MainPage() {

  const [cycles, setCycles] = useState([])
  const [cycle, setCycle] = useState<Cycle>()
  const [showCycle, setShowCycle] = useState<boolean>(false)

  useEffect(() => {
    loadCycles()

  }, [])


  async function loadCycles() {

    const { response, error } = await RequestLib.get('app/admin/cycles')
    if (error) {
      console.error(error.response)
      alert(error.response?.data?.message || 'ไม่สามารถแสดงข้อมูลได้ขณะนี้')
    } else {

      let list_cycle = (response.data?.data?.cycles || []).map((c: any) => {
        c.icon_view = <FontAwesomeIcon icon={faSearch} onClick={() => selectCycle(c)} />
        c.status_badge = getStatusBadge(c.status)
        c.lose_sum = c.lose_amount + ' (' + c.lose_rate + '%)'

        return c
      })

      setCycles(list_cycle)
    }

  }

  function getStatusBadge(status: string) {
    const inprogress = (status === 'inprogress')
    return <Badge bg={inprogress ? 'warning' : 'success'}>{inprogress ? 'กำลังเปิด' : 'สิ้นสุด'}</Badge>
  }

  async function selectCycle(e: any) {

    const { response, error } = await RequestLib.get('app/admin/cycle/' + e.serial)
    if (error) {
      console.error(error.response)
      alert(error.response?.data?.message || 'ไม่สามารถแสดงข้อมูลได้ขณะนี้')
    } else {

      let this_cycle = response.data?.data?.cycle
      this_cycle.results = (this_cycle.results || []).map((r: any) => {
        r.result = <Badge bg={r.is_win ? 'success' : 'danger'} >{r.is_win ? 'ชนะ' : 'แพ้'}</Badge>
        return r
      })

      setCycle(this_cycle)
      setShowCycle(true)
    }

  }

  return (
    <>
      <Row className="pt-4 mb-3">
        <Col>
          <h5>รอบการเล่น</h5>
        </Col>
      </Row>

      <DatatableWrapper body={cycles} headers={TABLE_HEADER}>
        <Table responsive hover >
          <TableHeader />
          <TableBody onRowClick={selectCycle} />
        </Table>
        <Row className="mb-4">
          <Col
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Pagination />
          </Col>
        </Row>
      </DatatableWrapper>

      {cycle &&
        <Modal show={cycle && showCycle} size="lg" onHide={() => setShowCycle(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              รอบ {cycle?.start_at} {cycle?.end_at ? ' - ' + cycle?.end_at : ''} <span className="mx-2">{getStatusBadge(cycle.status)}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Row>
              <Col xs md={3}>

                <Card text={'dark'} className="mb-2">
                  <Card.Header className="text-center">
                    <strong>วงเงิน</strong>
                  </Card.Header>
                  <Card.Body className="text-center">
                    <Card.Title>
                      {cycle.budget}
                    </Card.Title>
                  </Card.Body>
                </Card>

                <Card text={'dark'} className="mb-2">
                  <Card.Header className="text-center">
                    <strong>งบเสีย</strong>
                  </Card.Header>
                  <Card.Body className="text-center">
                    <Card.Title>
                      {cycle.lose_amount} ({cycle.lose_rate}%)
                    </Card.Title>
                  </Card.Body>
                </Card>

                <Card border={'success'} text={'dark'} className="mb-2">
                  <Card.Header className="text-center">
                    <strong>รวมเงินได้</strong>
                  </Card.Header>
                  <Card.Body className="text-center">
                    <Card.Title>
                      {cycle.win_current}
                    </Card.Title>
                  </Card.Body>
                </Card>

                <Card border={'danger'} text={'dark'} className="mb-2">
                  <Card.Header className="text-center">
                    <strong>เหลือเงินเสีย</strong>
                  </Card.Header>
                  <Card.Body className="text-center">
                    <Card.Title>
                      {cycle.lose_remain}
                    </Card.Title>
                  </Card.Body>
                </Card>

              </Col>

              <Col>


                <DatatableWrapper body={cycle.results} headers={TABLE_RESULT_HEADER}>
                  <Table responsive striped size="sm" >
                    <TableHeader />
                    <TableBody />
                  </Table>
                  <Row className="mb-4">
                    <Col
                      className="d-flex flex-col justify-content-end align-items-end"
                    >
                      <Pagination />
                    </Col>
                  </Row>
                </DatatableWrapper>
              </Col>
            </Row>

          </Modal.Body>
        </Modal>
      }

    </>
  )
}

export default MainPage