export const LOCAL_STORAGE_IP = 'ip'
export const LOCAL_STORAGE_TOKEN = 'token'
export const LOCAL_STORAGE_MEMBER = 'member'
export const LOCAL_STORAGE_URL_CALLBACK = 'url_callback'

export const LOCAL_ADMIN_TOKEN = 'admin_token'

export const LOCAL_STORAGE_SETTING = 'setting'


export const LOCAL_STORAGE_LIST = [
  LOCAL_STORAGE_IP, LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_MEMBER, LOCAL_STORAGE_URL_CALLBACK,
  LOCAL_ADMIN_TOKEN,
]