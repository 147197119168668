import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { useAppSelector } from '../../redux/Hook'

import LocalStorage from '../../libs/LocalStorage';

import { LOCAL_ADMIN_TOKEN } from '../../constants/LocalStorage'

import RequestLib from '../../libs/Request';


function LayoutComponent() {
  const navigate = useNavigate()
  const error_code = useAppSelector((state) => state.error.code)

  const [show, setShow] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')

  const token = LocalStorage.get(LOCAL_ADMIN_TOKEN)


  useEffect(() => {

    if (!token) {
      LocalStorage.clear()
      setShow(true)
    }

  }, [token])

  useEffect(() => {

    //if (error_code) navigate('/unauthorized')

  }, [error_code, navigate])


  async function login() {

    let data = {
      pincode: password,
    }

    const { response, error } = await RequestLib.post('app/admin/login', data)
    if (error) {
      console.error(error.response)
      alert(error.response?.data?.message || 'ไม่สามารถเข้าสู่ระบบได้ขณะนี้')
    } else {


      if (response.data?.success) {

        let jwtToken = response.data?.data?.token
        //console.log(jwtToken)

        LocalStorage.set(LOCAL_ADMIN_TOKEN, jwtToken)

        setShow(false)

        navigate('/admin/cycle')

      } else {
        alert(response.message)
      }
    }

  }


  return (
    <>
      {!show &&
        <>
          <Navbar className="bg-body-tertiary">
            <Container>
              <Navbar.Brand href="#home">ADMIN</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="/admin/cycle">รอบ</Nav.Link>
                  <Nav.Link href="/admin/report">รายงาน</Nav.Link>
                  <Nav.Link href="/admin/setting">ตั้งค่า</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Outlet />
        </>
      }


      <Modal
        size="sm"
        show={show}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Please enter Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Control type="password" size="lg" onChange={(e) => setPassword(e.target.value)} />

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="px-4" onClick={login}>เข้าสู่ระบบ</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default LayoutComponent