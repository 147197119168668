import { useEffect } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom";
import { Row, Col, Image } from 'react-bootstrap';

import LocalStorage from '../libs/LocalStorage'
import { LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_URL_CALLBACK, LOCAL_STORAGE_SETTING } from '../constants/LocalStorage'

import RequestLib from '../libs/Request'


import IconLogo from '../assets/images/rock-paper-scissors.png'

function AccessPage() {
  const navigate = useNavigate();


  const [qt] = useSearchParams()


  const token = qt.get('gmctoken') || ''


  useEffect(() => {

    const fetchData = async () => {
      LocalStorage.clear()

      const { response, error } = await RequestLib.post('app/authen/check', { token: token })
      if (error) {
        console.error(error)
        navigate('/unauthorized')
      } else {

        let callback_url = response?.data?.data?.callback_url
        if (callback_url) LocalStorage.set(LOCAL_STORAGE_URL_CALLBACK, callback_url)

        LocalStorage.set(LOCAL_STORAGE_TOKEN, response.data?.data?.token)

        let setting = LocalStorage.get(LOCAL_STORAGE_SETTING) || {}
        console.log('Access', 'setting', setting)
        if (!setting.sound) setting.sound = 'on'

        LocalStorage.set(LOCAL_STORAGE_SETTING, setting)

        start()

      }

    }


    if (token) fetchData()
    //else navigate('/unauthorized')

  }, [token, navigate])


  async function start() {

    navigate('/start')
  }

  return (
    <>
      <Row>
        <Col>
          <Image src={IconLogo} alt="" className=' py-4' fluid />
        </Col>
      </Row>
    </>
  )

}


export default AccessPage