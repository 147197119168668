import { useEffect, useState } from 'react'


import {
  Container, Row, Col, Button,
  Navbar, Image,
  ListGroup, Badge,
  Modal,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'

import IconBaht from '../../assets/images/baht.png'



import RequestLib from '../../libs/Request'



function MainPage() {


  useEffect(() => {
    load()

  }, [])


  async function load() {
  }


  return (
    <>
    </>
  )
}

export default MainPage