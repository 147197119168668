import { useEffect, useState } from 'react'


import {
  Row, Col, Button,
  Form,
  Tabs, Tab,
  InputGroup,
} from 'react-bootstrap'


import { sortBy, uniq } from 'lodash'
import { useDebounce } from 'usehooks-ts'

import RequestLib from '../../libs/Request'


const TAB_GAME = 'game'
const TAB_PASSWORD = 'password'
const AMOUNT_TOTAL = 6

interface GameData { budget: number, win_amount: number, lose_rate: number, lost_amount: number, amounts: number[] }

function MainPage() {

  const [tab, setTab] = useState<string>(TAB_GAME)
  const [gameData, setGameData] = useState<GameData>({ budget: 0, win_amount: 0, lose_rate: 0, lost_amount: 0, amounts: [] })
  const [amounts, setAmounts] = useState<number[]>([])
  const [allowSaveConfiguration, setAllowSaveConfiguration] = useState<boolean>(false)

  const [passwordData, setPasswordData] = useState<{ old: string, password: string, password2: string }>({ old: '', password: '', password2: '' })


  useEffect(() => {
    loadConfiguration('game')

  }, [])

  async function loadConfiguration(_type: string) {
    setAllowSaveConfiguration(false)

    const { response, error } = await RequestLib.get('app/admin/configuration/' + _type)
    if (error) {
      console.error(error.response)
      alert(error.response?.data?.message || 'ไม่สามารถแสดงข้อมูลได้ขณะนี้')
    } else {

      const { budget, lose_rate }: GameData = response.data?.data
      const amounts = JSON.parse(response.data?.data?.amounts)


      let data: any = { ...gameData, budget: budget, lose_rate: lose_rate, amounts: amounts }
      data.lost_amount = Math.floor((data.lose_rate * data.budget) / 100)
      data.win_amount = data.budget - data.lost_amount

      setGameData(data)
      setAmounts(data.amounts)
      setAllowSaveConfiguration(true)
    }

  }

  function setCycelData(field: string, value: number) {

    let data: any = { ...gameData }
    data[field] = value

    data.lost_amount = Math.floor((data.lose_rate * data.budget) / 100)
    data.win_amount = data.budget - data.lost_amount

    setGameData(data)

  }

  function setGameAmounts(no: number, value: number) {
    let list_amount = [...amounts]
    if (value && typeof value !== 'undefined') {
      list_amount[no] = value
    } else {
      if (list_amount[no]) {
        // list_amount.splice(no, 1)
      }
    }

    setAmounts(list_amount)
  }

  async function saveGameData(e: any) {
    e.preventDefault()

    gameData.amounts = uniq(amounts)
    gameData.amounts = sortBy(gameData.amounts)

    const { response, error } = await RequestLib.post('app/admin/configuration/game', gameData)
    if (error) {
      console.error(error.response)
      alert(error.response?.data?.message || 'ไม่สามารถบันทึกข้อมูลได้ขณะนี้')
    } else {
      alert('บันทึกข้อมูลเรียบร้อย')
    }
  }

  async function savePassword(e: any) {
    e.preventDefault()

    if (passwordData.password !== passwordData.password2) {
      alert('กรุณากรอก พิมพ์รหัสผ่านใหม่อีกครั้งให้ตรงกัน')
      return
    }


    const { response, error } = await RequestLib.post('app/admin/password', passwordData)
    if (error) {
      console.error(error.response)
      alert(error.response?.data?.message || 'ไม่สามารถบันทึกข้อมูลได้ขณะนี้')
    } else {
      alert('บันทึกข้อมูลเรียบร้อย')
    }

  }

  return (
    <>
      <Row className="py-4">
        <Col>
          <h5>ตั้งค่า</h5>
        </Col>
      </Row>
      <Row className="mb-3 pt-4 justify-content-md-center">
        <Col xs md={6}>
          <Tabs defaultActiveKey={tab} className="mb-3">
            <Tab eventKey={TAB_GAME} title="เกม" onClick={() => setTab(TAB_GAME)}>

              <Form onSubmit={saveGameData}>

                <Form.Group as={Row} className="mb-3" >
                  <Form.Label >
                    จำนวนเงินเล่น
                  </Form.Label>

                  {[...Array(AMOUNT_TOTAL)].map((v, i) => {

                    let amount = gameData.amounts[i]

                    return (
                      <Col sm="2" key={i}>
                        <Form.Control className="mb-2" type="number" min={1} defaultValue={amount || ''} onChange={(e) => setGameAmounts(i, parseInt(e.target.value))} />
                      </Col>
                    )
                  })}

                </Form.Group>

                <hr />

                <Form.Group as={Row} className="mb-3" >
                  <Form.Label column sm="3">
                    วงเงินต่อรอบ (BOT)
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control type="number" min={1} value={gameData.budget} required onChange={(e) => setCycelData('budget', parseInt(e.target.value))} />
                  </Col>
                  <Form.Label column className="text-danger">
                    * มีผลในรอบถัดไป
                  </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" >
                  <Form.Label column sm="3">
                    อัตราแพ้
                  </Form.Label>
                  <Col sm={4}>
                    <InputGroup >
                      <Form.Control type="number" min={0} max={100} value={gameData.lose_rate} required onChange={(e) => setCycelData('lose_rate', parseInt(e.target.value))} />
                      <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" >
                  <Form.Label column sm="3">
                    คิดเป็นเงินได้
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control readOnly plaintext value={gameData.win_amount || 0} />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" >
                  <Form.Label column sm="3">
                    คิดเป็นเงินเสีย
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control readOnly plaintext value={gameData.lost_amount || 0} />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" >
                  <Col sm={{ span: 4, offset: 3 }}>
                    <Button type="submit" variant="primary" className="px-4" disabled={!allowSaveConfiguration}>บันทึก</Button>
                  </Col>
                </Form.Group>
              </Form>

            </Tab>
            <Tab eventKey={TAB_PASSWORD} title="เปลี่ยนรหัสผ่าน" onClick={() => setTab(TAB_PASSWORD)}>


              <Form onSubmit={savePassword}>

                <Form.Group as={Row} className="mb-3" >
                  <Form.Label column sm="4">รหัสผ่านเดิม</Form.Label>
                  <Col >
                    <Form.Control type="password" required onChange={(e) => setPasswordData({ ...passwordData, old: e.target.value })} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" >
                  <Form.Label column sm="4">รหัสผ่านใหม่</Form.Label>
                  <Col >
                    <Form.Control type="password" minLength={6} maxLength={20} required onChange={(e) => setPasswordData({ ...passwordData, password: e.target.value })} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" >
                  <Form.Label column sm="4">พิมพ์รหัสผ่านใหม่อีกครั้ง</Form.Label>
                  <Col >
                    <Form.Control type="password" required onChange={(e) => setPasswordData({ ...passwordData, password2: e.target.value })} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" >
                  <Col sm={{ span: 4, offset: 4 }}>
                    <Button type="submit" variant="primary" className="px-4" >บันทึก</Button>
                  </Col>
                </Form.Group>
              </Form>

            </Tab>
          </Tabs>


        </Col>
      </Row>




    </>
  )
}

export default MainPage