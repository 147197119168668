import { useEffect, useState } from 'react'

import {
  Container, Row, Col,
  Navbar, Image, Card,
} from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { chunk } from 'lodash';

import CreateChallenge from '../components/challenge/Create'
import PlayChallenge from '../components/challenge/Play'
import SelectChallenge from '../components/challenge/Select'
import TopBar from '../components/TopBar'

import RequestLib from '../libs/Request'

import IconBaht from '../assets/images/baht.png'
import TextSelect from '../assets/images/text-select.png'
import ImageButtonRefresh from '../assets/images/button-refresh.png'
import ImageButtonCreate from '../assets/images/button-create.png'
import ImageChoices from '../assets/images/choices.png'

import Avatar1 from '../assets/images/avatar-1.png'
import Avatar2 from '../assets/images/avatar-2.png'
import Avatar3 from '../assets/images/avatar-3.png'

import ButtonSelect1 from '../assets/images/button-select-1.png'
import ButtonSelect2 from '../assets/images/button-select-2.png'
import ButtonSelect3 from '../assets/images/button-select-3.png'


const AVATAR = [Avatar1, Avatar2, Avatar3]
const BUTTONS = [ButtonSelect1, ButtonSelect2, ButtonSelect3]

const CHALLENGE_TIMEOUT = 30
var counterChallenge: any

interface Challenge {
  serial: string;
  code: string;
  username: string;
  amount: number;
  total_play: number;
  total_amount: number;
}

interface ChallengeRow {
  items: Challenge[];
}


function MainPage() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const query_play = searchParams.get('play')

  const [credit, setCredit] = useState<number>(0)
  const [challenges, setChallenges] = useState<ChallengeRow[]>([])
  const [challenge, setChallenge] = useState<Challenge>()
  const [showCreateChallenge, setShowCreateChallenge] = useState<boolean>(false)
  const [showPlayChallenge, setShowPlayChallenge] = useState<boolean>(false)
  const [showSelectChallenge, setShowSelectChallenge] = useState<boolean>(false)
  const [bet, setBet] = useState<{ show: boolean, start?: boolean, match: string }>({ show: false, start: false, match: '' })


  useEffect(() => {
    loadChallenge()

    if (query_play) confirmCreateChallenge(query_play, true)

  }, [])

  useEffect(() => {

    setShowPlayChallenge(bet.show)

  }, [bet])



  async function loadChallenge(create_amount: number = 0) {
    if (counterChallenge) clearTimeout(counterChallenge)

    let url = 'app/matches?status=wait'

    const { response, error } = await RequestLib.get(url)
    if (error) {
      console.error(error)
      alert('ไม่สามารถแสดงข้อมูลได้ขณะนี้')
    } else {

      if (response.data?.success) {

        const matches: Challenge[] = response.data?.data?.matches || []

        const rows = chunk(matches, 3)

        let list_match: ChallengeRow[] = []
        for (const row of rows) {
          list_match.push({ items: row })
        }
        setChallenges(list_match)
        //setChallenges(response.data?.data?.matches)

        const this_credit = response.data?.data?.credit || -1

        setCredit(this_credit)

        if (create_amount > 0) {
          if (create_amount < this_credit) {
            create(create_amount, 1)
          }
        } else {

          counterChallenge = setTimeout(() => { loadChallenge() }, CHALLENGE_TIMEOUT * 1000)

        }

      }
      else {
        console.error(response.data)
        alert('ไม่สามารถแสดงข้อมูลได้ขณะนี้')
      }

    }
  }


  async function selectChallenge(match: any) {
    if (match.total_amount > credit) {
      alert('จำนวนเครดิตไม่พอ ต้องการ ' + match.total_amount + ' เครดิต')
    }
    else {
      setChallenge(match)
      setShowSelectChallenge(true)
    }
  }

  function createChallenge() {
    //setShowCreateChallenge(true)

    navigate('/create')
  }

  async function confirmCreateChallenge(match: string, start?: boolean) {
    //console.log('confirmCreateChallenge', match, start)
    setBet({ show: true, start: start, match: match })
  }


  function closePlay(amount: number = 0) {
    if (query_play) {
      searchParams.delete('play')
      setSearchParams(searchParams)
    }

    setShowPlayChallenge(false);
    setBet({ ...bet, show: false })
    create(amount, 1)
    //loadChallenge(amount);
  }

  async function create(amount: number, total: number = 1) {

    if (amount > 0) {

      let data = {
        bet: amount,
        total: total || 1,
        //choice: bet?.choice || '',
      }

      const { response, error } = await RequestLib.post('app/match', data)
      if (error) {
        console.error(error)
        alert('ไม่สามารถสร้างได้ขณะนี้')
      } else {


        if (response.data?.success) {

          const match_serial = response.data?.data?.match
          //preparePlay(match_serial)
          confirmCreateChallenge(match_serial, true)

        } else {
          alert(response.data?.message)
        }
      }

    }

  }

  return (
    <>
      <TopBar />

      <Container>
        <Row className="mt-2 mb-2">
          <Col>
            <Image src={TextSelect} style={{ maxHeight: 35 }} />
          </Col>
          <Col xs={2}>
            <Image src={ImageButtonRefresh} style={{ maxHeight: 35 }} onClick={() => loadChallenge()} />
          </Col>
        </Row>


        {challenges.map((cols, i) => (
          <Row className="mb-2" key={i}>
            {cols.items.map((col, j) => {
              const no = j + 1
              const amount = col.amount

              return (
                <Col xs={4} key={j} className={'bg-match text-center bg-match-' + no} style={{ padding: 5, height: 200 }}>
                  <div >#{col.code}</div>

                  <Image src={AVATAR[j]} width={40} height={40} className="my-1" />

                  <div>
                    <strong>{col.username.toUpperCase()}</strong>
                  </div>

                  <div className="start-box-amount mb-2">
                    <Image src={IconBaht} width={14} height={14} /> เดิมพัน: {amount}
                  </div>

                  <Image src={ImageChoices} height={22} className="mb-2" />

                  <Image src={BUTTONS[j]} fluid className="px-2" onClick={() => selectChallenge(col)} />
                </Col>
              )
            })}
          </Row>
        ))}



        <Navbar fixed="bottom" >
          <Container>
            <Navbar.Offcanvas placement="end">
              <Image src={ImageButtonCreate} className="button-bar" onClick={createChallenge} />
            </Navbar.Offcanvas>
          </Container>
        </Navbar>

      </Container>

      <CreateChallenge show={showCreateChallenge} onSelect={confirmCreateChallenge} onClose={() => { setShowCreateChallenge(false); loadChallenge(); }} />
      <SelectChallenge show={showSelectChallenge} match={challenge?.serial || ''} amount={challenge?.amount || 0} onSelect={confirmCreateChallenge} onClose={() => { setShowSelectChallenge(false); loadChallenge(); }} />

      <PlayChallenge show={showPlayChallenge} start={bet?.start || false} match={bet.match} onClose={closePlay} />


    </>
  )
}

export default MainPage