
import { Alert } from 'react-bootstrap'

interface Props {
  variant?: string;
}

function Rule({ variant = 'warning' }: Props) {


  return (
    <Alert variant={variant}>
      <ul>
        <li>ในกรณีที่ผู้เล่นหลุดอกจากเกม หรืออินเตอร์เน็ตมีปัญหา ระบบจะดำเนินการเล่นให้โดยอัตโนมัติ</li>
        <li>ผู้เล่นจะต้องเลือกตัวเลือก ภายใน 5 วินาที ถ้าเกินระยะเวลาที่กำหนด ระบบจะเลือกให้โดยอัตโนมัติ</li>
      </ul>
    </Alert>
  )
}

export default Rule