import { isObject } from 'lodash'

import { LOCAL_STORAGE_LIST } from '../constants/LocalStorage'

var LocalStorage = {
  set: (name: string, data: any) => {
    let value = isObject(data) ? JSON.stringify(data) : data

    localStorage.setItem(name, value)
  },

  get: (name: string) => {
    let value: any
    let item: string = localStorage.getItem(name) || ''
    try {
      value = JSON.parse(item);
    } catch (e) {
      value = item
    }

    return value// localStorage.getItem(name)
  },

  remove: (name: string) => {
    localStorage.removeItem(name)
  },

  clear: (all?: boolean) => {

    if (all) localStorage.clear()
    else {
      for (const fld of LOCAL_STORAGE_LIST) localStorage.removeItem(fld)
    }
  }
}



export default LocalStorage