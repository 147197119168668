import { useEffect, useState } from 'react'


import {
  Container, Row, Col, Button,
  Navbar, Image,
  ListGroup, Badge,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'

import IconBaht from '../../assets/images/baht.png'



import RequestLib from '../../libs/Request'



function MainPage() {

  const [bet, setBet] = useState<{ show: boolean, start?: boolean, match: string }>({ show: false, start: false, match: '' })

  useEffect(() => {
    load()

  }, [])

  useEffect(() => {


  }, [bet])


  async function load() {
  }


  return (
    <>
      <Row className="py-2">
        <Col>
          <h5>เลือก Challenge</h5>
        </Col>
        <Col xs={2}>
        </Col>
      </Row>
      <Row className="mb-3 pt-4">
        <Col>
        </Col>
      </Row>




    </>
  )
}

export default MainPage