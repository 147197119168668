
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay-ts';


import { useAppSelector } from './redux/Hook'


import Layout from './components/Layout'
import AdminLayout from './components/admin/Layout';

import UnauthorizedPage from './pages/error/Unauthorized'
import AccessPage from './pages/Access'
import StartPage from './pages/Start'
import CreatePage from './pages/Create'

import AdminStartPage from './pages/admin/Start'
import AdminCyclePage from './pages/admin/Cycle'
import AdminReportPage from './pages/admin/Report'
import AdminSettingPage from './pages/admin/Setting'



const router = createBrowserRouter([
  {
    path: "/",
    Component: Layout,
    children: [
      {
        index: true,
        Component: AccessPage,
      },
      {
        path: "start/:match?",
        Component: StartPage,
      },
      {
        path: "create",
        Component: CreatePage,
      },
    ],
  },
  {
    path: "/admin",
    Component: AdminLayout,
    children: [
      {
        index: true,
        Component: AdminStartPage,
      },
      {
        path: 'cycle',
        Component: AdminCyclePage,
      },
      {
        path: 'report',
        Component: AdminReportPage,
      },
      {
        path: 'setting',
        Component: AdminSettingPage,
      },
    ],
  },
  {
    path: "/unauthorized",
    Component: UnauthorizedPage,
  },

])


function App() {
  const showLoading = useAppSelector((state) => state.loading.show)


  return (
    <>
      <LoadingOverlay
        active={showLoading}
        spinner
        text='Please wait...'
      >
        <RouterProvider router={router} />
      </LoadingOverlay>
    </>

  );
}

export default App;
