import { useEffect, useState } from 'react'

import {
  Row, Col, Button, Image,
  Modal,
  Form,
} from 'react-bootstrap'

import { filter, findIndex } from 'lodash'
import { useAppDispatch } from '../../redux/Hook'

import ChoicePaper from '../../assets/images/rps-1.png'
import ChoiceRock from '../../assets/images/rps-2.png'
import ChoiceScissor from '../../assets/images/rps-3.png'
import IconBaht from '../../assets/images/baht.png'

import RuleInfo from './Rule'


import '../../assets/css/TopBar.css'

import RequestLib from '../../libs/Request'

interface Props {
  show: boolean;
  onSelect: (serial: string, start?: boolean) => void;
  onClose: () => void;
}

interface Bet {
  amount: number;
  total: number;
  choice?: string;
}

const TOTAL_ROUND = 5;


function PlayPage({ show, onSelect, onClose }: Props) {
  const dispatch = useAppDispatch()

  const [credit, setCredit] = useState(0)
  const [bet, setBet] = useState<Bet>({ amount: 0, total: 1 })
  const [amounts, setAmounts] = useState<number[]>([])
  const [listAmount, setListAmount] = useState<number[]>([])
  const [rounds, setRounds] = useState<number[]>([])
  const [summary, setSummary] = useState<number>(0)
  const [choices, setChoices] = useState([
    { code: 'paper', icon: ChoicePaper, id: '' },
    { code: 'rock', icon: ChoiceRock, id: '' },
    { code: 'scissor', icon: ChoiceScissor, id: '' },
  ])

  useEffect(() => {
    setBet({ amount: 0, total: 1, choice: '' })
    if (show) load()

  }, [show])


  async function load() {

    const { response, error } = await RequestLib.get('app/match')
    if (error) {
      console.error(error)
      alert('ไม่สามารถแสดงข้อมูลได้ขณะนี้')
      close()
    } else {

      if (response.data?.success) {
        const current_credit = response.data?.data?.credit || 0

        setCredit(current_credit)
        setListAmount(response.data?.data?.amounts || [])

        let list_amount = filter(response.data?.data?.amounts || [], (amt) => { if (current_credit >= amt) return amt })
        setAmounts(list_amount)

        let list_round = []
        for (let i = 1; i <= TOTAL_ROUND; ++i) {
          list_round.push(i)
        }
        setRounds(list_round)

        let list_choice = [...choices]
        for (const ch of response.data?.data?.choices) {
          let idx = findIndex(list_choice, { code: ch.code })
          if (idx !== -1) list_choice[idx].id = ch.id
        }

        setChoices(list_choice)
      } else {
        alert(response.data?.message)
      }

    }
  }

  async function start() {

    let data = {
      bet: bet?.amount || 0,
      total: bet?.total || 1,
      //choice: bet?.choice || '',
    }

    const { response, error } = await RequestLib.post('app/match', data)
    if (error) {
      console.error(error)
      alert('ไม่สามารถสร้างได้ขณะนี้')
    } else {


      if (response.data?.success) {

        const match_serial = response.data?.data?.match
        preparePlay(match_serial)
        // close()

      } else {
        alert(response.data?.message)
      }
    }
    //onSelect(bet.amount, bet.choice)
    //close()
  }

  async function preparePlay(match_serial: string) {
    const { response, error } = await RequestLib.post('app/match/start', { match: match_serial })
    if (error) {
      console.error(error)
      alert('ไม่สามารถสร้างได้ขณะนี้')
    } else {

      if (response.data?.success) {

        const match_play_serial = response.data?.data?.match?.id

        onSelect(match_play_serial, true)
        close()

      } else {
        alert(response.data?.message)
      }
    }
  }

  function setValue(field: string, value: number) {
    const this_bet = { ...bet, [field]: value }

    const sum = (this_bet.amount || 0) * (this_bet.total || 1)

    setBet(this_bet)
    setSummary(sum)
  }

  function close() {
    if (onClose) onClose()
  }


  return (
    <Modal show={show} onHide={close} backdrop='static' fullscreen={true}>
      <Modal.Header closeButton>
        <Modal.Title>สร้าง Challenge</Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <Row>
          <Col className="text-center py-2">
            <h4>เครดิตปัจจุบัน</h4>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mb-4">
            <h1><span className="text-success">{credit}</span> บาท</h1>
          </Col>
        </Row>


        {(amounts.length > 0) ? (
          <>
            <Row>
              <Col className="text-center py-2">
                <h4>เลือกจำนวนเงินต่อรอบที่ต้องการเล่น</h4>
              </Col>
            </Row>

            <Row className="mb-4">
              {amounts.map((amount, i) => {
                return (
                  <Col xs={4} key={i}>
                    <div className="d-grid gap-2">
                      <Button className="mb-3" variant={(amount === bet?.amount) ? 'primary' : 'outline-primary'} size="lg" onClick={() => setValue('amount', amount)}><img src={IconBaht} className="icon-coin" alt="" /> {amount}</Button>
                    </div>
                  </Col>
                )

              })}
            </Row>
          </>
        ) : (
          <>

            <Row>
              <Col className="text-center py-2">
                <h4>ระบุจำนวนเงินต่อรอบ</h4>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs={{ span: 6, offset: 3 }}>
                <Form.Control type="number" size="lg" className="text-center" min={1} max={credit} style={{ fontSize: 24 }} required onChange={(e) => setValue('amount', parseInt(e.target.value))} />
              </Col>
            </Row>

          </>
        )}


        {(credit > 0) &&
          <>
            <Row>
              <Col className="text-center py-2">
                <h4>จำนวนรอบที่ต้องการเล่น</h4>
              </Col>
            </Row>
            <Row className="justify-content-xs-center mb-4">

              {rounds.map((r, i) => {
                return (
                  <Col xs={4} key={i}>
                    <div className="d-grid gap-2">
                      <Button className="mb-3" variant={(r === bet?.total) ? 'primary' : 'outline-primary'} size="lg" onClick={() => setValue('total', r)}>{r}</Button>
                    </div>
                  </Col>
                )

              })}
            </Row>

            <Row>
              <Col className="text-center ">
                <h4>รวมเป็นเงินทั้งหมด</h4>
              </Col>
            </Row>
            <Row>
              <Col className="text-center py-2">
                <h1><span className="text-primary">{summary}</span> บาท</h1>
              </Col>
            </Row>

            {(summary > credit) &&
              <Row>
                <Col className="text-center py-2 text-danger">
                  <h3>จำนวนเครดิตไม่เพียงพอ</h3>
                </Col>
              </Row>
            }
          </>
        }

        {(amounts.length > 0 && false) &&
          <>
            <Row>
              <Col className="text-center py-2">
                <h4>เลือก</h4>
              </Col>
            </Row>
            <Row className="justify-content-xs-center">
              {choices.map((choice, i) => (
                <Col xs={6} className="mb-3" key={i}>
                  <Button variant="outline-secondary" active={(choice.id === bet?.choice)} onClick={() => setBet({ ...bet, choice: choice.id })}><Image src={choice.icon} fluid /></Button>
                </Col>
              ))}
            </Row>
          </>
        }


        <RuleInfo variant="warning" />

      </Modal.Body>

      <Modal.Footer style={{
        display: "flex",
        justifyContent: "center",
      }}>

        {(summary < credit && summary > 0) ? (
          <Button variant="primary" size="lg" className="px-4" onClick={start} disabled={!(bet.amount > 0 && (summary) <= credit)}>เริ่มเล่น</Button>
        ) : (
          <Button variant="secondary" size="lg" className="px-4" onClick={close} >ปิด</Button>
        )}
      </Modal.Footer>
    </Modal>


  )
}

export default PlayPage