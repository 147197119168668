import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../Store'


import LocalStorage from '../../libs/LocalStorage'
import { LOCAL_STORAGE_MEMBER } from '../../constants/LocalStorage'

interface MemberState {
  username: string
  name: string
  credit?: number
}

const initialState: MemberState = {
  credit: 0,
  username: '-',
  name: '-',
}

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setCredit: (state, action: PayloadAction<number>) => {
      state.credit = action.payload
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload

      LocalStorage.set(LOCAL_STORAGE_MEMBER, state.username)
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload

      LocalStorage.set(LOCAL_STORAGE_MEMBER, state.name)
    },
    setInfo: (state, action: PayloadAction<MemberState>) => {
      state = { ...state, ...action.payload }

      LocalStorage.set(LOCAL_STORAGE_MEMBER, state)
    }
  }
})

export const { setCredit, setUsername } = memberSlice.actions
export const getMemberCredit = (state: RootState) => state.member.credit
export const getMemberUsername = (state: RootState) => state.member.username
export default memberSlice.reducer